<template>
    <header id="header-outer">
      <div id="header-logo">
        <router-link to="/">
        <img alt="Facette Lübeck Logog quadratisch" src="@/assets/logo/facetten-luebeck-logo-rect-no-text.svg">
        </router-link>
      </div>
      <div id="header-social">
        <a href="https://www.instagram.com/facetten_luebeck/" target="_blank"><img src="@/assets/icons/instagram.svg" alt=""></a>
        <a href="https://facebook.com/facetten_luebeck/" target="_blank"><img src="@/assets/icons/facebook.svg" alt=""></a>
        <span class="not-on-mobile">/facetten_luebeck</span>
      </div>
      <div id="header-title"><h1>Facetten</h1><span class="not-on-mobile">von Jetzt bis Morgen</span></div>
      <div id="header-slogan">Bühne trifft Wissenschaft <span class="not-on-mobile"> - ein Theaterspaziergang durch die Lübecker Innenstadt</span></div>
      <div id="header-navbar">
        <router-link to="#veranstaltung">Die Veranstaltung</router-link>
        <router-link to="#orte">Szenen & Orte</router-link>
        <router-link to="#künstlerinnen">Die Künstler*innen</router-link>
        <router-link to="#unterstützerinnen">Die Unterstützer*innen</router-link>
      </div>

    </header>
    <router-view/>
    <footer>
        <router-link :to="{ name: 'Impressum'}">Impressum & Datenschutz</router-link> <br><br>
        <span class="credits">Design und Entwicklung by <a href="https://luona.dev" target="_blank">[luona.dev]</a></span>
    </footer>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
.credits {
  & a {
    text-decoration: none;
    font-family: 'Abel', sans-serif;
    flex-grow: 1;
  }
}

blockquote {
  font-style: italic;
  background: #f9f9f9;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  margin-bottom: 1px;
  + figcaption {
    margin-left: 50px;
    margin-bottom: 15px;
    & :before {
      content: '-';
    }
  }
}

footer {
  display: flex;
  justify-content: center;
  padding: 10px;
  & a {
    flex-grow: 1;
  }
}

body {
  margin: 0;
  background-color: $body-bg;
  color: $text-color;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

#app {
  width: 100vw;
  max-width: $max-content-width;
  min-height: 200vh;
  background-color: $app-bg;
  & > section > h2 {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.main-content {
  padding: 10px;

  &div {
    padding: 5px;
  }
}

a {
  color: $link-color;
  text-decoration: underline;
  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    text-transform: uppercase;
    font-weight: 300;
  }
}

#header-outer {
  z-index: 99999;
  position: sticky;
  top: 0;
  background-color: tint-color($app-bg, 20%);
  // height: $headerheight;
  display: grid;
  grid-template-areas:
    "logo heading heading soc"
    "logo slogan slogan slogan"
    "nav nav nav nav";
  grid-template-columns: 10% auto auto auto;
  grid-template-rows: minmax(30%, 50px) minmax(30%, 50px) minmax(30%,20px);
  height: $headerheight;
  @media (max-width: $max-content-width) {
    height: $headerheightsmall;
    grid-template-areas:
      "logo heading heading soc"
      "logo slogan slogan slogan"
      "nav nav nav nav";
    grid-template-rows: 30% 25% 45%;
    grid-template-columns: 20% 25% 25% 30%;
  }

  // position: sticky;
  // height: 120px;
  // display: flex;
  // align-items: center;
  // top: -50px; /*Equal to the height difference between outer and inner header */
}

#header-social {
  grid-area: soc;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * > img {
    height: 30px;
    padding-right: 5px;
    margin-top: 10px;
  }
}

// #header-subtitle {
//   grid-area: sub;
// }

.not-on-mobile {
  @media (max-width: $max-content-width) {
    display: none !important;
  }
}

#header-navbar {
  grid-area: nav;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color:shade-color($primary, 3%);
  padding: 10px;
  a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 300;
  }
}
#header-slogan {
  font-weight: 500;
}

// #header-inner {
//   display: flex;
//   flex-wrap: wrap;
//   // align-items: baseline;
//
//   // height: 70px;
//   // position: sticky;
//   // top: 0;
// }

#header-title {
  grid-area: heading;
  align-self: center;
  justify-self: start;
  & > * {
    display: inline;
    padding-right: 10px;
  }
}

#header-slogan {
  grid-area: slogan;
  align-self: center;
}

#header-logo {
  display: flex;
  justify-content: center;
  grid-area: logo;
  & img {
    height: 100%;
    max-width: 100%;
    // max-height: calc(#{$headerheight} - 30px);
  }
}


.szene-1-col {
  background-color: $szene-1;
  color: $light;
}
.szene-2-col {
  background-color: $szene-2;
  color: $light;
}
.szene-3-col {
  background-color: $szene-3;
  color: $light;
}
.szene-4-col {
  background-color: $szene-4;
  color: $light;
}
.szene-5-col {
  background-color: $szene-5;
  color: $light;
}
.szene-6-col {
  background-color: $szene-6;
  color: $light;
}


</style>
