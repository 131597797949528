<template>
<section id="künstlerinnen">
  <h2>Die Künstler*innen</h2>
  <div class="künstlerinnen-block szene-1-col">
    <div class="künstlerinnen-foto künstlerinnen-foto--odd">
      <img src="@/assets/künstlerinnen/annazurnieden.jpeg" alt="Die Künstlerin Anna zur Nieden">
    </div>
    <div id="annzurnieden" class="künstlerinnen-text künstlerinnen-text--odd">
      <h3>Anna zur Nieden <small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
      Anna zur Nieden studierte Kunst und Französisch in Münster, Paris und Berlin bei Prof. Mechthild Frisch und Prof. Fußmann. Sie hospitierte während des Studiums an renommierten Berliner Theatern. Anschließend erhielt sie verschiedene Stipendien für ihre künstlerische Arbeit und ließ sich in London im Kostümbild fortbilden. Es folgten zahlreiche Gruppen- und Einzelausstellungen im In- und Ausland sowie Kostüm- und Raumgestaltungen für freie Theaterprojekte. Gelegentlich kuratiert sie Ausstellungen mit ungewöhnlichem Format. Das Theaterprojekt „FACETTEN – von Jetzt bis Morgen“ hat sie eigens für den öffentlichen Raum entwickelt und kuratiert.
    </div>
  </div>
  <div id="michelleseydoux" class="künstlerinnen-block szene-2-col">
    <div class="künstlerinnen-text">
      <h3>Michèle Seydoux<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
      Die gebürtige Schweizerin schloss ihre Ausbildungen zur klassischen Bühnentänzerin an den Hochschulen in Zürich und München ab. Ihr erstes Engagement führte sie zum Ballett desTheaters Dortmund und anschliessend tanzte sie an der Deutschen Oper am Rhein in Düsseldorf. Seit nun zwölf Jahren ist sie an der Staatsoper Hannover engagiert und hat hier auch schon diverse eigene Choreografien entwickelt, die unter anderem auch beim Festival TANZtheater International und am LOT Theater in Brauschweig gezeigt wurden, sowie bei "All you can dance" an der Staatsoper Hannover.
      Zudem ist Michèle Seydoux auch Yogalehrerin und unterrichtet Tanzworkshops.
    </div>
    <div class="künstlerinnen-foto">
      <img src="@/assets/künstlerinnen/michelleseydoux.jpg" alt="Die Künstlerin Michèle Seydoux">
    </div>
  </div>
  <div id="davidsioni" class="künstlerinnen-block szene-2-col">
    <div class="künstlerinnen-foto künstlerinnen-foto--odd">
      <img src="@/assets/künstlerinnen/davidesioni.jpg" alt="Der Künstler Davide Sioni">
    </div>
    <div class="künstlerinnen-text künstlerinnen-text--odd">
      <h3>Davide Sioni<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
      In Italien geboren erhielt Davide Sioni seine Tanzausbildung in Wien und in Zürich. Seit der Spielzeit 2016/17 ist er Mitglied des Balletts der Staatsoper Hannover, welches nun unter der Leitung von Marco Goecke steht.
    </div>
  </div>
  <div id="violaheess" class="künstlerinnen-block szene-3-col">
    <div class="künstlerinnen-text">
      <h3>Viola Heeß<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
      Viola Heeß wurde in Stuttgart geboren. Nach dem Abitur begann sie ein
      Tanzstudium in New York an der Martha Graham School of Contemporary
      Dance. Von 2004 bis 2008 studierte sie Schauspiel an der Akademie für
      darstellende Kunst in Ulm. Währenddessen spielte sie bereits am Theater Ulm
      bei Andreas von Studnitz, sowie am Akademietheater Ulm.
      Ihre Engagements führten sie an verschiedene Häuser in Bremen, Ravensburg,
      Celle, Düsseldorf, Feuchtwangen, Hamburg, ans Stadttheater Plauen-Zwickau,
      Stadttheater Osnabrück und ans Ernst Deutsch Theater Hamburg. Des Weiteren

      arbeitete sie erfolgreich mit der freien Gruppe Büro Steinheimer im Gerhart-
      Hauptmann-Museum Berlin/Erkner und seit Sommer 2020 mit der

      neugegründeten Performance Gruppe „Performing Females“.
      Sie ist regelmäßig als Sprecherin tätig und im TV zu sehen (Tatort,
      Pfefferkörner, Großstadtrevier) und tourt mit ihrem eigenen Chanson-Abend
      „Cabaret Größenwahn“.
      www.violaheess.de
      Foto: Jeanne Degraa
    </div>
    <div class="künstlerinnen-foto">
      <img src="@/assets/künstlerinnen/violaheeß.jpeg" alt="Die Künstlerin Viola Heeß">
    </div>
  </div>
  <div id="moritzschilk" class="künstlerinnen-block szene-3-col">
    <div class="künstlerinnen-foto künstlerinnen-foto--odd">
      <img src="@/assets/künstlerinnen/moritzschilk.jpg" alt="Der Künstler Moritz Schilk">
    </div>
    <div class="künstlerinnen-text künstlerinnen-text--odd">
      <h3>Moritz Schilk<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
        Moritz Schilk wurde in Hamburg geboren. Nach dem Abitur absolvierte er seine
        Ausbildung an der Freien Schauspielschule Hamburg und ist seit seinem Abschluss
        2016 als freiberuflicher Schauspieler aktiv. Bei den Kreuzgangspielen Feuchtwangen
        spielte er in Shakespeares Romeo und Julia den Romeo. 2017 absolvierte er eine
        Joker-Ausbildung im Theatre for Living bei dem Kanadier David Diamond und leitet
        seitdem Theaterworkshops zu diversen gesellschaftlichen Konfliktthemen. Er ist
        Botschafter für die Erd-Charta und Referent im Bildungsprojekt der
        Menschenrechtsorganisation peace brigades international.
        https://filmmakers.de/moritz-schilk
        Foto: Jeanne Degraa
    </div>
  </div>
  <div id="johanneskritzinger" class="künstlerinnen-block szene-4-col">
    <div class="künstlerinnen-text">
      <h3>Johannes Kritzinger<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
      Johannes Kritzinger erhielt seine Ballett-Ausbildung bei Heino Heiden (1970-1973) und an der John Cranko Schule in Stuttgart (1973-1976).Von 1976 bis 1985 war er Halb-Solist des Stuttgarter Balletts.

      1985 wurde er von John Neumeier an der Hamburgische Staatsoper als Solist nach Hamburg verpflichtet. Engagements als Erster Solist unter Ballettdirektor Pierre Wyss führten ihn nach Wiesbaden (1990-1992) und Braunschweig (1993-1994).

      1994 übernahm er mit seiner Frau Caroline Maylin-Kritzinger sowohl die Ballettschule Heino Heiden als auch das Lübecker Kinder-Tanztheater.

      1996 wird er als eingetragenes Mitglied in der Prominentenenzyklopädie „Who is who“ in der Bundesrepublik Deutschland gewählt.
      Im selben Jahr wurde er  künstlerischer Leiter für Choreographie und Tanz im Theater Partout Lübeck.

      2003 war er künstlerischer Beirat im Vorstand des Deutschen Berufsverbandes  für Tanzpädagogik e.V.

      2012 Jury bei den Dance Award Schleswig Holstein.

      Johannes Kritzinger ist auch künstlerischer Berater der Schwanke Stiftung zur Förderung des Ballett- und Tanztheaters in Lübeck.

      Schon seit seinen Stuttgarter Jahren ist er stets auch als Choreograph tätig.

      Seit 1994 arbeitet er als Choreograph in der freien Szene.

      Seit 2012 ist er als Gast Ballettmeister und Gast Choreograph im Landestheater Coburg tätig.

    </div>
    <div class="künstlerinnen-foto">
      <img src="@/assets/künstlerinnen/johanneskritzinger.jpg" alt="Der Künstler Johannes Kritzinger">
    </div>
  </div>
  <div id="christinagumz" class="künstlerinnen-block szene-5-col">
    <div class="künstlerinnen-foto künstlerinnen-foto--odd">
      <img src="@/assets/künstlerinnen/christinagumz.jpg" alt="Die Künstlerin Christina Gumz">
    </div>
    <div class="künstlerinnen-text künstlerinnen-text--odd">
      <h3>Christina Gumz<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
      Christina Gumz, auf gewachsen in Sachsen-Anhalt, sammelte erste Bühnenerfahrungen
      beim Schultheater, wo sie u.a. in Stücken von Goethe, Kleist und Shakespeare auftrat. Von
      2000 bis 2007 lebte sie in Paris und nahm dort Schauspielunterricht im Théâtre du
      Lucernaire und anschließend am Conservatoire d'art dramatique Charles Munch. Im
      Théâtre du Lucernaire trat sie unter anderem in “Les Papotins” und “Don Quichotte” auf,
      sowie beim Festival d' Avignon. Zudem schloss sie ein Literatur- und Sprachstudium
      (Anglistik) an der Sorbonne mit einer Maîtrise über Samuel Beckett ab.
      2005 gründete sie zusammen mit Clément Labail das Théâtre Au fil des nuages. Über
      mehrere Jahre war sie als Kursleiterin für Kinder- und Erwachsenentheater in Paris tätig
      und setzt diese Arbeit auch in Deutschland an unterschiedlichen Orten fort.
      Des Weiteren ist sie als Übersetzerin tätig. So überträgt sie u.a. die Texte von Clément
      Labail für das Théâtre Au fil des nuages ins Deutsche, aber auch Lyrik und Prosa von
      Matthias de Breyne, Wilfried N'Sondé und Rodica Draghincescu.
      <p>
        <a href="https://christinagumz.wordpress.com/">www.christinagumz.com</a>
      </p>
    </div>
  </div>
  <div id="clementlabail" class="künstlerinnen-block szene-5-col">
    <div class="künstlerinnen-text">
        <h3>Clément Labail<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
        Clément Labail, gebürtiger Franzose, entdeckte die Schauspielerei in der
        Nähe von Rouen (Frankreich): im Gymnasium “Les Bruyères” und im
        Théâtre Maxime Gorki. Dort trat er unter anderem in Stücken von Dario Fo
        und Catherine Anne auf und spielte unter der Leitung von Jacques Kraemer in “Les
        Cobayes”. Es folgte eine Ausbildung am Conservatoire der Normandie, in verschiedenen
        Workshops, an der Universität Paris 3 – Sorbonne Nouvelle und schließlich im Théâtre du
        Lucernaire, bevor er mit Christina Gumz das “Théâtre Au fil des nuages” gründete.
        Zudem ist Clément Labail als Autor tätig und schreibt sowohl Theaterstücke als auch
        Poesie, welche von verschiedenen Zeitschriften und im Verlag “A Verse” veröffentlicht
        wurden: (link: http://au-fil-des-nuages.net/#04_03_d).
        Von 1998 bis 2007 arbeitete er in Freitzeitzentren von Paris und Umgebung mit Kindern,
        Jugendlichen und Erwachsenen als Kursleiter für Theater. Seit 2007 wohnhaft in
        Deutschland, setzt er diese Arbeit mit Kindern, Jugendlichen und Studenten fort
        (Workshops, Kurse, Schulen). Zudem ist er regelmäßig als Sprecher für Dokumentarfilme
        im Einsatz.
        <p>
          <a href="https://clementlabail.wordpress.com/">www.clementlabail.com</a>
        </p>
    </div>
    <div class="künstlerinnen-foto">
      <img src="@/assets/künstlerinnen/clementlabail.jpg" alt="Der Künstler Clément Labail">
    </div>
  </div>
  <div id="nilzbessel" class="künstlerinnen-block szene-6-col">
      <div class="künstlerinnen-foto künstlerinnen-foto--odd">
        <img src="@/assets/künstlerinnen/nilzbessel.jpg" alt="Der Künstler Nilz Bessel">
      </div>
      <div class="künstlerinnen-text künstlerinnen-text--odd">
        <h3>Nilz Bessel<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
        Nilz Bessel ist gelernter Schauspieler und Sprecher, seit 2008 auf diversen Bühnen und anderen
        Spielstätten tätig. Seit er nicht mehr soviel Zeit im Süden verbringt, lebt und arbeitet er wieder in
        Hamburg. Von 2018 bis 2020 war er im Bereich des interaktiven Improvisationstheaters tätig und
        arbeitet vermehrt als Sprecher und Synchronsprecher.
      </div>
  </div>
  <div id="tosjamdkruppa" class="künstlerinnen-block szene-6-col">
    <div class="künstlerinnen-text">
        <h3>Tosja M. D. Kruppa<small style="float:right;"><small><router-link to="#orte">&#x2191; zu den szenen</router-link></small></small></h3>
        Tosja M. D. Kruppa ist gelernter Schauspieler und seit 2016 als solcher aktiv. Neben einigen
        Bühnenproduktionen war und ist er in diversen Werbespots zu sehen und durfte auch schon für das
        Öffentlich-Rechtliche drehen. Von 2018 bis 2020 war er im Bereich des interaktiven
        Improvisationstheaters tätig und veröffentlichte außerdem 2021 seinen ersten Roman.
        <p>
          Nilz Bessel und Tosja M. D. Kruppa haben sich 2018 kennengelernt und machten bis 2020 gemeinsam
          interaktives Improtheater. Dabei machte es es ihnen mindestens genauso viel Spaß, ihr Publikum zu
          unterhalten, wie auch es zu fordern. Sie freuen sich sehr darauf, das erste Mal seit Beginn der Pandemie
          wieder gemeinsam auf der Bühne zu stehen.
        </p>
      </div>
      <div class="künstlerinnen-foto">
        <img src="@/assets/künstlerinnen/tosjakruppa.jpg" alt="Der Künstler Tosja M. D. Kruppa">
      </div>
    </div>
</section>

</template>

<script>
export default {
  name: 'Künstlerinnen',
}
</script>

<style scoped lang="scss">
.künstlerinnen-block {
  display: flex;
  justify-content: flex-start;
}
.künstlerinnen-foto {
  align-self: flex-start;
  position: sticky;
  top: $headerheight;
  margin: 10px 10px 10px 0;
  padding: 10px;
  background-color: #FFFFFF33;
  @media (max-width: $max-content-width) {
    top: $headerheightsmall;
  }
  > img {
    max-width: 25vw;
  }
}

.künstlerinnen-foto--odd {
  margin: 10px 0 10px 10px !important;
}

.künstlerinnen-text--odd {
  margin: 10px 10px 10px 0 !important;
}

.künstlerinnen-text {
  background-color: #FFFFFF33;
  align-self: flex-start;
  margin: 10px 0 10px 10px;
  padding: 5px;
  position: sticky;
  top: calc(#{$headerheight} + 10px);
  @media (max-width: $max-content-width) {
    top: $headerheightsmall;
  }
}
</style>
