<template>
<section id="unterstützerinnen">
  <h2>Die Unterstützer*innen</h2>
  <div class="text-block">
    <p>
      Diese Veranstaltung wird komplett durch Spenden und Fördergeldern finanziert.
      Wir danken folgenden Vereinen und Stiftungen, die diese Veranstaltung ermöglichen:
    </p>
  </div>
  <div class="logo-block">
    <figure style="flex: 1 1 90%;">
      <img src="@/assets/unterstützerinnen/possehl.jpg" alt="">
    </figure>
    <figure>
      <span>Bluhme-Jebsen-Stiftung</span>
    </figure>
    <figure>
      <span>ENERPARK GmbH</span>
    </figure>
    <figure>
      <img src="@/assets/unterstützerinnen/ballettfreunde.png" alt="">
    </figure>
    <figure>
      <img src="@/assets/unterstützerinnen/drager.jpg" alt="">
    </figure>
    <figure>
      <img src="@/assets/unterstützerinnen/kulturstadt.jpg" alt="">
    </figure>

  </div>
  <div class="text-block">
    Wir danken:
    <ul>
      <li>
        Prof. Dr. Thomas Biebricher, Philosophie und Politikwissenschaften
      </li>
      <li>
        Elisabeth Dankhoff, Psychologie
      </li>
      <li>
        Bacar Gadji, Afrikanische Gesellschaft
      </li>
      <li>
        Dr. Anders Hermund, Architektur
      </li>
      <li>
        Prof. Dr. Michael Koß, Politikwissenschaften
      </li>
      <li>
        Prof. Michael Locher, Stadtplanung
      </li>
      <li>
        Prof. Dr. Dirk Notz, Ozeanologie
      </li>
      <li>
        Prof. Lydia Rintz, Stadtplanung
      </li>
      <li>
        Phillip Sewing, Weltbank
      </li>
      <li>
        Prof. Iris Stahlke, soziologische Psychologie
      </li>
      <li>
        Charlotte Stenzel, Greenpeace
      </li>
      <li>
        Prof. Stefan Borgwardt
      </li>
      <li>
        Oliver Grieve, UKSH
      </li>
      <li>
        Bernd Schwarze, St. Petri Lübeck
      </li>
    </ul>
  </div>

</section>
<section id="spenden">
  <div class="text-block grey" >
    <h2>Spenden</h2>
    <p>
      Es besteht die Möglichkeit unser Projekt statt mit Eintrittskarten, mit Spenden zu unterstützen. Ab einer Unterstützung von 10€ oder mehr, haben Sie die Möglichkeit, ein "Soli-Ticket" für die Veranstaltung zu erhalten. Schreiben Sie uns in diesem Fall einfach eine E-Mail mit Ihrem Namen, dem Verwendungszweck „Facetten-Soli-Ticket“ und der Information, ob Sie das Ticket digital oder per Post erhalten möchten.
    </p>
    <p>
      Kontakt: <a href="mailto:info@facetten-luebeck.de">info@facetten-luebeck.de</a>
    </p>
    <p>
      Bankverbindung: Lübecker Ballettfreunde – IBAN: DE 65 230 50 101 00 1995 40 80 (Betreff: FACETTEN)
    </p>
    <p>
      Oder per <a href="https://www.paypal.com/pools/c/8BNiDulg5r">PayPal</a>
    </p>
  </div>
</section>
</template>

<script>
export default {
  name: 'Unterstützerinnen',
}
</script>

<style scoped lang="scss">
.text-block {
  margin: 10px;
}
.grey {
  padding: 10px;
  background-color: shade-color($primary, 5%)
}
.logo-block {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  > * {
    padding: 25px;
    flex: 1 1 40%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    > img {
      max-width: 100%;
      height: auto;
    }
    > span {
      max-width: 100%;
      font-size: 3rem;
      font-family: 'Abel', sans-serif;

    }
  }
}
</style>
