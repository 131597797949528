<template>
  <Intro></Intro>
  <Orte></Orte>
  <Künstlerinnen></Künstlerinnen>
  <Unterstützerinnen></Unterstützerinnen>
</template>

<script>
// @ is an alias to /src
import Intro from '@/components/Intro.vue'
import Orte from '@/components/Map.vue'
import Künstlerinnen from '@/components/Künstlerinnen.vue'
import Unterstützerinnen from '@/components/Unterstützerinnen.vue'


export default {
  name: 'Home',
  components: {
    Intro,
    Unterstützerinnen,
    Künstlerinnen,
    Orte,
  }
}
</script>
