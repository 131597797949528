<template>
<section id="veranstaltung">
  <h2>Die Veranstaltung</h2>
  <img src="../assets/logo/facetten-luebeck-logo-full.svg ">
  <div>
    <div id="intro-overview">
      <p>
        <b>
          FACETTEN – von Jetzt bis Morgen
        </b>
      </p>
      <p>
        eine Idee von Anna zur Nieden
      </p>
      <p>
        THEATER | BALLETT | MUSIK | TANZ | KOSTÜMPERFORMANCE
      </p>
      <p>
        Am 28. und 29. August 2021
      </p>
      <p>
        Eintritt frei, <router-link to="#spenden">Spenden</router-link> erwünscht!
      </p>
    </div>
    <p>
      Das Theaterprojekt „FACETTEN – von Jetzt bis Morgen“ ist aus dem Gedanken entstanden, den öffentlichen Raum stärker für die Wünsche und Bedürfnisse der Bürgerinnen und Bürger nutzbar zu machen und ihn als Ort der Kommunikation zu verstehen.
    </p>
    <p>
      Schauspieler und Tänzerinnen führen an verschiedenen Orten der Altstadtinsel Szenen auf, die sich mit aktuellen gesellschaftlichen Fragen an die Zukunft beschäftigen. Wie werden wir körperliche Nähe empfinden? Welche Dynamik entsteht im häuslichen Zusammenleben? Welchen Einfluss werden andere Kulturen auf uns haben? Wie werden wir mit der Natur zusammenleben? Welche politischen Strömungen werden sich etablieren?
    </p>
    <p>
      Als Inspiration dienten dabei Einschätzungen unterschiedlicher Expertinnen und Wissenschaftler aus u.a. den Bereichen der Klimaforschung, Psychologie, Medizin, Politik und Stadtplanung.
    </p>
    <blockquote>„Denn der Erfolg des derzeitigen Systems und seiner Anreize ist der Mißerfolg bezüglich des Schutzes der Lebensgrundlagen.“</blockquote><figcaption>Philipp Sewing, Weltbank New York</figcaption>
    <blockquote>“Das Grundrauschen hinter den vielen politischen Konflikten der Gegenwart bildet der Geschlechterkonflikt. Vordergründig stehen wirtschaftliche transnationale Fragen zur Debatte, also Umverteilung und die Haltung zum Klimawandel, der Migration und der EU. Hintergründig aber ist die Unsicherheit so groß, dass Männer mit Abstiegssorgen den Verlust ihrer Privilegien fürchten und deshalb antifeministische Positionen vertreten.”</blockquote>
    <blockquote>“Demokratie ist kein Service, der vom Amazon-Boten geliefert wird. Stattdessen müssen wir selbst unsere politischen Forderungen durchsetzen, und zwar in der Regel, indem wir Konflikte austragen, entweder in Bewegungen oder vor allem in und zwischen politischen Parteien.”</blockquote><figcaption>Prof. Dr. Michael Koß, Politikwissenschaften, Leuphana Universität Lüneburg</figcaption>
    <blockquote>"Die Pandemie hat unser Verhältnis zum körperlichen Miteinandersein nachhaltig gestört, sei es das gemeinsame Tanzen in Clubs oder auch nur die Praxis des Händeschüttelns. Werden wir auch nach der Pandemie körperliche Nähe nur mit latentem Unbehagen erfahren können oder wird unsere (Körper-)Kultur in ihren vorherigen Zustand zurückpendeln - oder werden wir gar lernen, körperliches Miteinandersein in realen Räumen auf eine neue Art und Weise zu zelebrieren?"</blockquote><figcaption>Prof. Dr. Thomas Biebricher, Business School Copenhagen</figcaption>
    <blockquote>„Eine nachhaltige, menschenwürdige Welt ist keine Welt des Verzichts, sondern eine Welt der Fülle: voller intensiver Momente, voller Wertschätzung für die wirklich wichtige Arbeit, voller freier Entfaltung, voller Zeit für das Wesentliche.“</blockquote><figcaption>
Prof. Dr. Dirk Notz, Ozeanologie, Universität Hamburg</figcaption>
    <blockquote>„Es ist eindeutig, dass die Welt ein Dorf ist und wir alle eng zusammengehören und voneinander abhängig sind, ob aus der südlichen Hemisphäre oder aus der nördlichen.“</blockquote><figcaption> Bacar Gadji, Afrikanische Gesellschaft Lübeck</figcaption>
    <blockquote>„Künftig sind Raumkonzepte gefragt, die eine Kopplung des flexibleren Arbeitens und Wohnens von Beginn an anlegen, und die im größeren räumlichen Maßstab den Bedürfnissen einer diversen und deutlich mobileren Gesellschaft gerecht werden.“</blockquote><figcaption>Prof. Lydia Rintz, Technische Hochschule Lübeck</figcaption>
  </div>
  <div>


  </div>
</section>
</template>

<script>
export default {
  name: 'Intro',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#intro-overview {
  background-color: shade-color($primary, 5%);
  padding:1px 7px;

}

section {
  > img {
    display: inline-block;
    vertical-align: top;
    width: 46%;
    position: sticky;
    top: $headerheight;
    @media (max-width: $max-content-width) {
      width: 100%;
      position: unset;
    }
  }
  > div {
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    width: 46%;
    @media (max-width: $max-content-width) {
      width: 100%;
    }
  }
}
</style>
