<template lang="html">
  <h1>Impressum & Datenschutz</h1>
  <h2>Impressum</h2>
  <p>
    Dieses Impressum gilt für die Seite facetten-luebeck.de, sowie für die dazugehörigen Seiten auf <a href="https://facebook.com/facetten_luebeck/" target="_blank">Facebook</a> und auf <a href="https://www.instagram.com/facetten_luebeck/" target="_blank">Instagram</a>.
  </p>
  <div class="block block-even">
    <b>Verantwortlich für den Inhalt dieser Seite, sowie für Konzept und künstlerische Leitung ist:</b>
    <p>
      Lübecker Ballettfreunde e. V.
    </p>
    <p>
      Dornbreite 139
    </p>
    <p>
      23556 Lübeck
    </p>
    <p>
      <b>Website: </b> <a href="http://annazurnieden.de">annazurnieden.de</a>
    </p>
    <p>
      <b>Kontakt:</b> <a href="mailto:info@facetten-luebeck.de">info@facetten-luebeck.de</a>
    </p>
  </div>
  <div class="block block-odd">
    <p>
      <b>Verantworlich für die Projektleitung ist:</b>
    </p>
    <a href="mailto:bianca-paola.cavallaro@hfmt-hamburg.de">Bianca Paola Cavallaro</a>
  </div>
  <div class="block block-even">
    <p>
      <b>Webentwicklung und -design wurden durchgeführt von:</b>
    </p>
    <a href="https://luona.dev">[luona] development</a>
  </div>
  <h2>Datenschutz</h2>
  <div class="block block-odd">
    <p>Bei der Benutzung dieser Website werden keinerlei persönliche Daten erhoben.</p>
    <p>Wenn Du eine E-Mail an eine der hier aufgeführten Adressen schickst, erklärst du dich selbstverständlich damit einverstanden, dass die dabei übermittelten persönlichen Daten zur Bearbeitung deines Anliegens und für die dafür erforderliche Dauer gespeichert und verwendet werden.</p>

    Die Seite enthält Links zu externen Webseiten Dritter, auf deren Inhalte keinerlei Einfluss besteht. Deshalb wird für diese Inhalte keine Gewähr übernommen. Für die Inhalte der verlinkten Seiten ist ausschließlich der jeweilige Anbieter bzw. Betreiber der Seiten verantwortlich; alle verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zu diesem Zeitpunkt nicht erkennbar. Eine andauernde inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernt.
  </div>
</template>

<script>
export default {
  name: 'Impressum'

}
</script>

<style lang="scss" scoped>
.block {
  display: block;
  padding: 20px;
}

.block-even {
  background-color: shade-color($primary, 5%);

}

.block-odd {
  background-color: shade-color($primary, 10%);

}
</style>
