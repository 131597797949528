<template>
<section id="orte">
  <h2>Szenen & Orte</h2>
  <div id="themap">
  </div>
  <div id="szenen-info-box">
    <div v-for="s in szenen" :key="s.szenen_nr">
      <transition name="mode-fade" mode="out-in">
        <div v-show="s.szenen_nr == selected_szene" :class="'szene-' + s.szenen_nr + '-col'">
          <div class="szenen-header">
            <span class="btn btn-prev" aria-label="Previous slide" @click="prev()">
              &#10094;
            </span>
            <h3>Szene {{s.szenen_nr}} - "{{s.szenen_name}}"</h3>
            <span class="btn btn-next" aria-label="Next slide" @click="next()">
              &#10095;
            </span>
          </div>
          <div class="szenen-body">
            <div class="szenen-meta">
              <b>Ort: </b><a :href="'https://www.google.com/maps/dir/?api=1&destination=' + s.ort_latitude + ',' + s.ort_longitude" target="_blank">{{s.ort_name}}</a><br>
              <b>Am 28.08.</b> um {{s.uhrzeiten_tag1}}<br>
              <b>Am 29.08.</b> um {{s.uhrzeiten_tag2}}<br>
              <b>Dauer:</b> {{s.szenen_länge}} Minuten<br>
              <b>Was: </b>{{s.szenen_typ}}<br>
            </div>
            <div class="szenen-desc" v-html="s.szenen_beschreibung">
            </div>
            <div>
              <b>Mehr über </b><span v-for="(k, i) in s.künstlerinnen" :key="i"><router-link :to="'#' + s.kürzel[i]">{{k}}</router-link> | </span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>

</section>
</template>

<script>
import szenen from '@/assets/szenen.json'
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

var customMarker = L.Marker.extend({
  options: {
    szenen_info_id: ''
  }
});

export default {
  name: 'Orte',
  data() {
    return {
      map: null,
      szenen: szenen,
      selected_szene: 1,
    };
  },
  methods: {
    next() {
      this.selected_szene == 6? this.selected_szene =1: this.selected_szene ++;
    },
    prev() {
      this.selected_szene == 1? this.selected_szene =6: this.selected_szene --;
    }
  },
  mounted() {
    let that = this
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    that.map = L.map("themap", { gestureHandling: true }).setView([51.959, -8.623], 12);
    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
      attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      subdomains: 'abcd',
      minZoom: 0,
      maxZoom: 20,
      ext: 'png'
    }).addTo(that.map);
    let szenen_marker = that.szenen.slice(0).reverse().map((szene) => {
      var icon = L.divIcon({'className': 'map-icon marker-szene-' + szene.szenen_nr, 'html': '<img src="/markers/szene-' + szene.szenen_nr + '.svg"><span>' + szene.szenen_nr + '</span>', 'iconSize': 50})
      var marker = new customMarker([szene.ort_latitude, szene.ort_longitude],
                                    {szenen_info_id: '#szenen-info-' + szene.szenen_nr,
                                     icon: icon})
      marker.addEventListener('click', function(){
        that.selected_szene = szene.szenen_nr;
      })
      return marker
    });
    const all_szenen = L.featureGroup(szenen_marker);
    that.map.addLayer(all_szenen)
    that.map.fitBounds(all_szenen.getBounds().pad(0.1));

  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }
}
</script>

<style lang="scss" scoped>
.mode-fade-enter-active {
  transition: opacity .75s ease
}
.mode-fade-leave-active {
  display: none;
  transition: opacity 0;
}

.mode-fade-enter-from, .mode-fade-leave-to {
  opacity: 0
}

.szenen-header {
  display: flex;
  justify-content: space-between;
  .btn {
    z-index: 10;
    margin: 2px;
    cursor: pointer;
    border: 2px solid shade-color($primary, 20%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease-in-out;
    user-select: none;
  }
}

.szenen-body {
  & > * {
    margin-top: 3px;
    padding: 10px;
  }
}



.szenen-desc {
  background-color: #FFFFFF33;
}
#themap {
  width: 100%;
  height: 60vh;
}

</style>

<style lang="scss">

.map-icon {
  // border:3px solid $secondary;
  // border-radius: 50%;
  // background-color: $primary;
  width: 50px !important;
  height: 50px !important;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  & img {
    width: 100%;
  }
  & span {
    position: absolute;
    top: 0;
  }
}

.marker-szene-1 {
  & span {
    left: 25%;
    color: $light;
  }
}
.marker-szene-2 {
  & span {
    left: 38%;
    color: $light;
  }
}
.marker-szene-3 {
  & img {
    height: 100%;
    width: unset;
  }
  & span {
    left: 65%;
    color: $light;
  }
}
.marker-szene-4 {
  & span {
    left: 42%;
    color: $light;
  }
}
.marker-szene-5 {
  & img {
    height: 100%;
    width: unset;
  }
  & span {
    left: 24%;
    color: $light;
  }
}
.marker-szene-6 {
  z-index: 999 !important;
  & img {
    height: 100%;
    width: unset;
  }
  & span {
    left: 34%;
    color: $light;
  }
}
</style>
